import React from 'react';
import {Row, Col, Form, Input, Button, message, Slider, Tooltip, Icon, Modal} from 'antd';
import {IconTypes} from '@/constants/common';
import PropTypes from 'prop-types';
import _ from "lodash";
import QRCode from 'qrcode.react';
import moment from "moment";
import style from '@/style/components/dataSet/dataSetInfo.less';
import {API_aiEvntDirect,API_DataSetOrderCreat,API_DataSetTradeOrderCheckState,API_DataSetProductsOrderCheckState} from "@/api/system";
import {convertPrice, copyUrlToBoard} from '@/components/common/common.functions'
import {CsvToHtmlTable} from "react-csv-to-table"


class DataSetInfo extends React.Component {
  state = {
    id: null,
    dataset_info:{
      title:'',
      detail:'',
      thumb:'',
      exp:'',
      similar:''
    },
    havePay:false
  };

  shak=false;

  payModal = null;
  setTimeoutCheckState = null;
  checkStateNum = 0;

  constructor (props) {
    super(props);
    const {id} = props;
    if (!id) {
      this.state = {
        ...this.state,
        id,
		//dataset_info:localStorage.getItem(id)
      };
    }
  }

  componentDidMount() {
	  let me = this;
	  this.getDsetInfo(me.props.id);
  }

  componentWillReceiveProps (nextProps, nextState) {
    if (nextProps) {
      const {id} = nextProps;
      if (this.props.id !== id) {
		this.getDsetInfo(id);
      }
    }
  }

  getDsetInfo = (dset_title) => {
    let me = this;
    if(dset_title != ''){
      let params = {
        'func_name': 'get_dset_fields',
        'dset_title': dset_title
      };
      API_aiEvntDirect(params).then(response => {
        if(response.data.code == 0){
          let dataset_info = response.data.data && response.data.data[0] || {};
          this.setState({id:dset_title,dataset_info});
          me.checkDatasetPayState(dset_title);
          let params_exp = {
            'func_name': 'get_dset_demo',
            'dset_title': dset_title
          };
          API_aiEvntDirect(params_exp).then(response => {
            if(response.data.code == 0){
              let dset_demo = response.data.data[2];
              let exp_h = Object.keys(dset_demo[0]);
              let exp_list = [];
              exp_list.push(exp_h.join('|||'));
              dset_demo.forEach((item)=>{
                let exp_v =[];
                exp_h.forEach((im) =>{
                  exp_v.push(item[im]);
                })
                exp_list.push(exp_v.join('|||'));
              })
              dataset_info['exp'] = exp_list.join('\n');
              this.setState({dataset_info});
            }else{
              //this.datasetList = []
            }
            }).catch(error => {
              console.warn(error);
            });
          let params_similar = {
              'func_name': 'get_dset_similar',
              'dset_title': dset_title
            };
          API_aiEvntDirect(params_similar).then(response => {
              if(response.data.code == 0){
                dataset_info['similar'] = response.data.data;
                this.setState({dataset_info});
              }else{
                //this.datasetList = []
              }
              }).catch(error => {
                console.warn(error);
              });
          
        }else{
          //this.datasetList = []
        }
        }).catch(error => {
        console.warn(error);
        });
    }else{
      message.warn('参数错误！')
    }
  };
  
  /**
     * 购买
     */
  creatOrder = (dset_title) => {
    let me = this;
    if(me.shak){
      return ;
    }
    me.shak = true;
    let params ={
      trade_type: 1,
      order_type: 1,
      product_list: [{"title":dset_title,"price":me.state.dataset_info.price*100}],
      id_user: localStorage.getItem('userId')
    }
    API_DataSetOrderCreat(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          let trade = response.data.data;
          if(trade.total_fee==0){
            //
          }else{
            me.doPayModal(trade); //扫码支付
          }
        }else{
          message.info(response.data.msg, 3);
        }
      }else{
        message.info('网络不稳定，请稍后重新发起购买请求。', 3);
      }
    }).catch((e) => {
      console.log('common.viewManager->payView->获取订单出错 e=', e);
      // console.log('common.viewManager->payView->获取订单出错 view=', view)
      // message.error('网络不稳定，请在窗口关闭后重做上架操作。...', 3).then(() => payViewModal.destroy())
      message.error('网络不稳定，请稍后重新发起购买请求。', 3)
      //callback && callback({action: 'pay', viewId: view.viewId, success: false})
    });
    setTimeout(()=>{
      me.shak=false;},1000);
  }


  doPayModal  = (trade) => {
    let me = this;
    let op_name = "数据集";
    me.payModal = Modal.confirm({
      centered: true,
      title: "炬图-"+op_name+"-"+this.props.id,
      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
      okText: "我已支付成功",
      cancelText: "取消",
      okButtonProps: {
        style: {display:'none'},
      },
      width: 650,
      content:
          <div style={{
              height: '100%',
              width: '100%',
              minHeight: '300px',
              paddingTop: '0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
          }}>
              <div style={{
                  flex: '1',
                  height: '100%',
                  display: 'flex',
                  paddingTop: '20px'
                  //alignItems: 'center',
              }}>
                  <div style={{flex: '1',lineHeight:2}}>
                      <div>订单：{trade.trade_no}</div>
                      <div>标题：{op_name+"-"+this.props.id}</div>
                      {trade.total_fee == trade.product_price && <div>价格：{convertPrice(trade.total_fee)}元</div>}
                      {trade.total_fee != trade.product_price && <div>价格：{convertPrice(trade.total_fee)}元(<span style={{textDecoration:'line-through'}}>{convertPrice(trade.product_price)}</span>元)</div>}
                      <div>说明：支付完成后即可下载“{this.props.id}”</div>
                      <div><br/></div>
                  </div>
                  <div style={{flex: '0 1 35%', textAlign: 'center'}}>
                      <span><Icon name="wechat" style={{fontSize: '1rem', color: 'green'}}/> 微信扫码支付</span>
                      <div style={{
                          padding: '10px 10px 5px 10px ',
                          margin: '0 auto',
                          maxWidth: '150px',
                          textAlign: 'center',
                      }}>
                          <QRCode value={trade.code_url} title={'请使用微信扫码支付'}></QRCode>
                      </div>
                      <span>交易金额：<b style={{color: 'red'}}>{convertPrice(trade.total_fee)} 元</b><br/> </span>
                  </div>
              </div>
              <div style={{flex: '0 1 30%'}}>
                  {false && <div>
                      <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>成功</b>，请点击 <b
                      style={{color: 'red'}}>【我已支付成功】</b> 按钮。
                  </div>}
                  <div>
                      <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>失败</b>，请点击 <b
                      style={{color: 'red'}}>【取消】</b>重新购买。
                  </div>
              </div>
          </div>,
      onOk: () => {
        if(me.setTimeoutCheckState != null){
          clearTimeout(me.setTimeoutCheckState);
          me.setTimeoutCheckState = null;
        }
        me.checkStateNum = 0;
        me.checkPayState(trade.trade_no,true);
        me.payModal.destroy();
        me.payModal = null;
      },
      onCancel: () => {
        message.warning('您取消了支付操作')
        me.payModal.destroy();
        me.payModal = null;
        if(me.setTimeoutCheckState != null){
          clearTimeout(me.setTimeoutCheckState);
          me.setTimeoutCheckState = null;
        }
      },
    })
    me.checkStateNum = 0;
    me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade.trade_no,false);},3000);
  }

  checkPayState = (trade_no,op=false) => {
    let me = this;
    me.checkStateNum = me.checkStateNum+1;
    let params ={
      trade_no:trade_no,
      id_user: localStorage.getItem('userId')
    }
    API_DataSetTradeOrderCheckState(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          if(response.data.data.trade_no==trade_no){
            if(response.data.data.state==1){
              //message.info('已支付成功！');
              //me.props.onCancel();
              //显示下载链接
              me.showDownload(true);
              if(me.payModal != null){
                me.payModal.destroy();
                me.payModal = null;
              }
              if(me.setTimeoutCheckState != null){
                clearTimeout(me.setTimeoutCheckState);
                me.setTimeoutCheckState = null;
              }
            }else{
              let checkNum = op?30:300;
              if(me.checkStateNum < checkNum){
                me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},op?1000:3000);
              }
            }
          }
        }else{
          if(op){
            message.info(response.data.msg, 3);
          }else{
            if(me.checkStateNum<20){
              me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
            }
          }
        }
      }else{
        if(op){
          message.info('网络不稳定，请稍后再试。', 3);
        }else{
          if(me.checkStateNum<10){
            me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
          }
        }
      }
    }).catch((e) => {
      if(op){
        message.info('网络不稳定，请稍后再试。', 3);
      }else{
        if(me.checkStateNum<10){
          me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
        }
      }
    });
  }

  checkDatasetPayState = (dset_title) => {
    let me = this;
    let params ={
      products: [dset_title],
      id_user: localStorage.getItem('userId')
    }
    API_DataSetProductsOrderCheckState(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          let data_list = response.data.data;
          console.error('s1',data_list);
          if(data_list && data_list.length>0 && data_list[0].state==1){
            me.showDownload(true);
          }else{
            me.showDownload(false);
          }
        }else{
          me.showDownload(false);
        }
      }
    }).catch((e) => {
      me.showDownload(false);
      console.warn('网络不稳定，请稍后再试。');
    });
  }

  showDownload = (val) => {
		this.setState({
			havePay: val,
		});
	};

  
  doDownload = (dset_title) => {
    if(dset_title != ''){
      let params = {
        'func_name': 'get_dset_downloadurl',
        'dset_titles': [dset_title]
      };
      API_aiEvntDirect(params).then(response => {
        if(response.data.code == 0){
          let url = response.data.data[0][1];
          window.open(`${url}`, '_blank');
        }else{
          //this.datasetList = []
        }
        }).catch(error => {
        console.warn(error);
        });
    }else{
      message.warn('参数错误！')
    }
  };

  openDset = (e,dset) => {
    e.stopPropagation();
    this.props.history.push(`/mainview/dataset/datasetinfo/${dset}`);
		//window.open(`/mainview/dataset/datasetinfo/${dset}`);
		return;
	};

  humanFileSize = (bytes) => {
    const thresh = 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10**1;
    do {
      bytes /= thresh;
      ++u;
    } 
    while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }


  render() {
    let me = this;
    // noinspection RequiredAttributes
	console.error('d-1:',me.state.dataset_info);
    return (
      <div className={`${style['frame']} dark-theme`} style={{padding:'5rem'}}>
		<div className={style['dataset_box']} >
			<div className={style['dataset_img_box']}>
				<div className={style['dataset_img']}>
					{
						me.state.dataset_info.thumb ? (
							<img src={'data:image/png;base64,'+me.state.dataset_info.thumb}/>
						) : <img src={'/assets/view-default-cover.jpg'}/>
					}
				</div>
			</div>
			<div className={style['dataset_info_box']}>
				<div className={style['dataset_info_box_content']}>
					<div>
						<h2>{me.state.id}</h2>
					</div>
					<div style={{marginTop: '-0.1rem'}}>
						<p>{me.state.dataset_info.detail}</p>
					</div>
					<div className={style['dataset_info_items_box']}>
						<span className={style['dataset_info_item']} >  
							<span className={style['dataset_info_item_name']} >列数：</span>  
							<span className={style['dataset_info_item_val']} style={{color: 'rgb(52, 152, 219)'}}>{me.state.dataset_info.cnt_col}</span>  
						</span>  
						<span className={style['dataset_info_item']}>  
							<span className={style['dataset_info_item_name']}>行数：</span>  
							<span className={style['dataset_info_item_val']} style={{color: 'rgb(46, 204, 113)'}}>{me.state.dataset_info.cnt_row}</span>  
						</span>  
						<span className={style['dataset_info_item']}>  
							<span className={style['dataset_info_item_name']}>大小：</span>  
							<span className={style['dataset_info_item_val']} style={{color: 'rgb(231, 76, 60)'}}>{me.humanFileSize(me.state.dataset_info.cnt_size)}</span>  
						</span>  
						<span className={style['dataset_info_item']}>  
							<span className={style['dataset_info_item_name']}>更新：</span>  
							<span className={style['dataset_info_item_val']} style={{color: 'rgb(155, 89, 182)'}}>{me.state.dataset_info.dt_add}</span>  
						</span>  
					</div>
					<div className={style['dataset_info_items_box']}>
						<span className={style['dataset_info_item']} >  
							<span className={style['dataset_info_item_name']} >¥</span>  
							<span className={style['price-container']} >{me.state.dataset_info.price}</span>  
						</span>
						<span className={style['dataset_info_item']} >  
							  
						</span>
					</div>
          <div className={style['dataset_info_items_box']}>
						<span className={style['dataset_info_item']} >  
              {!me.state.havePay && <Button className={style['buy_button']} type='primary' onClick={() => {me.creatOrder(this.props.id)}}>立即购买</Button>} 
              {me.state.havePay && <Button type='primary' onClick={() => {me.doDownload(this.props.id)}}>下载数据集</Button>}
						</span> 
						<span className={style['dataset_info_item']} >  
							  
						</span>
					</div>
				</div>
			</div>
			
			<div className={style['dataset_exp_box']} >
				<div>
					<h3>💡数据集样例</h3>
				</div>
				<div className={style['dataset_exp_show']}>
					{me.state.dataset_info && me.state.dataset_info['exp'] &&
					<CsvToHtmlTable data={me.state.dataset_info['exp']} csvDelimiter="|||" tableClassName="table table-striped table-hover"/>
					}
				</div>
			</div>
      <div className={style['dataset_exp_box']} >
				<div>
					<h4>🔍 推荐其他数据集</h4>
				</div>
				<div className={style['dataset_similar_show']}>
        {me.state.dataset_info['similar'] && me.state.dataset_info['similar'].length>0 && 
          <ul>
            {me.state.dataset_info['similar'].map((item,idx) => (<li><a onClick={(e) => this.openDset(e,item)}   >{idx+1}. {item}</a></li>))}
          </ul>}
				</div>
			</div>
		</div>
      </div>
    );
  }
}

const WrappedDataSetInfo = Form.create()(DataSetInfo);

WrappedDataSetInfo.defaultProps = {
  id:''
};

WrappedDataSetInfo.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object,
};

export default WrappedDataSetInfo;