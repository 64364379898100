import React from 'react';
import {Button, Layout, message, Modal} from 'antd';
import {connect} from 'react-redux';
import {Route, Redirect, Switch} from "react-router-dom";
import {store} from '@/store';
import toolbarStyle from '@/style/components/main.toolbar.less';
import style from '@/style/containers/mainView.1.0.1.less'

import {SysUIConfig} from "@/constants/sys.config";

// 部件
// import VisualToolMini from "@/components/common/relation/common.relation.visualToolMini";
import BusinessTool from '@/components/mainView/main.toolbar.work'
import RelationSearch from '@/components/common/relation/common.relation.search'

// 模块
import DashBoard from '@/containers/dashboard';
import Relation from '@/components/mainView/main.relation.1';
import AccountCenter from '@/containers/mainView.accountCenter';
import ExtendedFunctionality from '@/containers/extendedFunctionality';
import DataSet from '@/containers/dataset';


import {Copyright} from '@/components/framework/frame.components'
import Feedback from '@/components/feedback/feedback.1.0';
import Printing from '@/components/printing/printing';
import Loading from "@/components/common/common.loading";
import {
  changePwdInitAction,
  changeUserBasicInfoAction,
  doChangePwdAction,
  doLogoutAction,
  doMagicLoginAction,
} from "@/redux-saga/account/actions";
import {
  submitFeedbackAction,
  submitPrintingAction,
} from '@/redux-saga/system/actions';
import PB from '@/libs/simplePB'
import {TYPE_FIELD_NAME} from "@/constants/vis.defaultDefine.1";
import {LoginStatusEnum} from "@/constants/account";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ViewManager from '@/libs/view/ViewManager';
import Guide from '@/components/guide'

import Qs from "qs";
import BadgeInfo from "@/components/mainView/main.badgeInfo";
import JoinTypeModal from "@/components/common/view/common.view.config.joinTypeModal";
import View from "@/components/common/common.viewManager";
import StatisticsUserNodePanel from "@/components/common/view/statistics/common.view.statistics.userNodePanel";
import NodeFilter from "@/components/common/relation/common.relation.nodeFilter";
import StatisticsIndexPlugin from "@/components/common/view/statistics/index/common.view.statistics.index.plugin";
import {NetworkEvents} from "@/libs/view/network/events";
import ShortcutMenu from '@/components/mainView/main.toolbar.shortcut';
import RelationAIConsole from "@/components/common/relation/common.relation.aiConsole";
import ExploreToolBar from "@/components/common/view/explore/common.view.explore.toolBar";
import NodeTodo from "@/components/common/node/todo/common.node.todo";
import FileList from "@/components/mainView/main.view.fileList";
import StickyNodes from '@/components/common/common.stickyNodes';
import ChartsToolbarNew from "@/components/mainView/toolbar/main.toolbar.nav";
import moment from 'moment';
// 提示教程组件
import TutorialCourser from '@/components/common/tutorial/tutorial.course';
import intl from 'react-intl-universal';

const {Content} = Layout;

class MainView extends React.Component {
  state = {
    viewId: undefined, // 当前显示的图谱id
    sBarCollapsed: true, // 切换左侧栏的拉伸状态
    // 加载页面遮罩
    viewLoading: {
      status: false,
      content: undefined, // 显示文字
    },
    showJoinTypeModal: false,

    showUserNodeAmountStatisticsModal: false, // 是否展示用户节点数统计对话框
    userNodeAmountStatisticsUserInfo: undefined, // 统计对应用户信息

    viewVisionConfig: undefined, // 图谱视觉配置

    showFileList: false, // 是否显示附件列表对话框
    hideAiDialogBoxFlag: -1,
    hideInputBoxFlag: -1,
    hideMicroservicesBoxFlag: -1,
    hideViewBoxFlag: -1,
    currentNodeId: undefined
  };

  calcElement = undefined; // 用于计算rem与px转换比例的隐藏层

  remToPx = undefined; // rem与px的转换比例

  network = new ViewDataProvider();

  fileListModalStyle = {top: '15vh'};

  autoStartPresentation = false;

  graphOnly = false;

  isJoinmapUser = false;

  projectId = undefined;

  reloadTeamworkOnly = undefined;

  constructor(props) {
    super(props);

    if (props.location && props.location.search) {
      let query = Qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
      });
      if (query['presentation'] === true) {
        this.autoStartPresentation = true;
      }
      if (query['graph_only']) {
        this.graphOnly = true;
        window.graphReady = false;
      }
      if (query['joinmap_user']) {
        this.isJoinmapUser = true;
      }
      if (query['project_id'] && query['project_id'] !== 'builtin') {
        this.projectId = query['project_id'];
      }
      if (query['reload_teamwork_only']) {
        this.reloadTeamworkOnly = true;
      }

    }
  }

  componentWillMount() {
    // 检测登录状态
    const me = this;
    const {loginStatus} = this.props;
    console.error('w1',window.location.hostname,localStorage.getItem('token'))
    if(window.location.hostname==="redshield.joinmap.ai"){  //||window.location.hostname==="localhost"
        if (!localStorage.getItem('token')){
          this.props.magicLogin();
          setTimeout(() => me.refreshPage(), 500);
        }
    }else{
      if(window.location.hostname!=="ustcsv.joinmap.ai"){
        if (loginStatus !== LoginStatusEnum.SUCCESS) {
          this.props.history.push("/login");
        }
      }else{
        if (!localStorage.getItem('token')){
          this.props.history.push("/login");
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // 检测登录状态
    const me = this;
    const {loginStatus} = nextProps;
    //if(window.location.hostname==="redshield.joinmap.ai"||window.location.hostname==="localhost"){  //||window.location.hostname==="localhost"
    //  if (loginStatus !== LoginStatusEnum.SUCCESS) {
    //    setTimeout(() => me.refreshPage(), 500);
    //  }
    //  return true;
    //}
    if(window.location.hostname==="redshield.joinmap.ai"){
      if (loginStatus !== this.props.loginStatus) {
        setTimeout(() => me.refreshPage(), 500);
      }
    }
    if(window.location.hostname!=="ustcsv.joinmap.ai"){
      if (loginStatus !== LoginStatusEnum.SUCCESS) {
        window.location.href = "/login";
        return false;
      }
    }else{
      if (!localStorage.getItem('token')){
        this.props.history.push("/login");
        return false;
      }
    }
    return true;
  }

  componentWillUnmount() {
    PB.remove(this);
    this.network.unSubscribe(this);
  }

  componentDidMount() {
    if (this.autoStartPresentation) {
      let autoStartTimeout = setTimeout(() => PB.emit('relation', 'presentation.do_start', {config: {}}), 5000);
      PB.once(this, 'relation', ['presentation.do_pause', 'presentation.do_hold'], () => {
        clearTimeout(autoStartTimeout);
      });
    }

    this.network.cacheStore = store;

    PB.sub(this, 'view', 'config.join_type_modal.on_show',
      () => this.setState({showJoinTypeModal: true}));

    PB.sub(this, 'view', 'info.on_edit', () => {
      this.network.viewInfo && View.editView(this.network.viewInfo, () => {
      });
    });

    PB.sub(this, 'statistics', 'show_user_node_panel', (/*{userInfo}*/) => {
      // this.setState({showUserNodeAmountStatisticsModal: true, userNodeAmountStatisticsUserInfo: userInfo});
    });

    PB.sub(this, 'view', 'view.info.update', (/*{ viewId, viewInfo }*/) => {
    });

    PB.sub(this, 'view', 'view.file_list_modal.show', () => {
      this.setState({showFileList: true});
    });

    PB.sub(this, 'view', 'view.aibox.do', ({viewId,hide_ai_dialog_box_flag}) => {
      localStorage.setItem('hide_ai_dialog_box_flag', hide_ai_dialog_box_flag);
			this.setState({hideAiDialogBoxFlag: hide_ai_dialog_box_flag}, () => {
      });
		});

    PB.sub(this, 'view', 'view.inputbox.do', ({viewId,hide_input_box_flag}) => {
      localStorage.setItem('hide_input_box_flag', hide_input_box_flag);
			this.setState({hideInputBoxFlag: hide_input_box_flag}, () => {
      });
		});

    PB.sub(this, 'view', 'view.microservicesbox.do', ({viewId,hide_microservices_box_flag}) => {
      localStorage.setItem('hide_microservices_box_flag', hide_microservices_box_flag);
			this.setState({hideMicroservicesBoxFlag: hide_microservices_box_flag}, () => {
      });
		});

    PB.sub(this, 'view', 'view.viewbox.do', ({viewId,hide_view_box_flag}) => {
      localStorage.setItem('hide_view_box_flag', hide_view_box_flag);
			this.setState({hideViewBoxFlag: hide_view_box_flag}, () => {
      });
		});

    PB.sub(this, 'relation', 'node.current.do', (currentNodeId) => {
      this.setState({currentNodeId}, () => {
      });
		});

    this.network.sub(this, NetworkEvents.LOADING_STRUCTURE_SUCCESS, () => {
      this.network.loadConfig('$.viewConfig.vision').then(config => {
        config = config || {multiLevelBrightness: false};
        if (config.multiLevelBrightness === true) {
          config.multiLevelBrightness = 'time';
        }
        this.setState({viewVisionConfig: config}, () => this.forceUpdate());
      }).catch(({code, msg}) => {
        console.log(`加载视觉配置失败，code: ${code}, msg: ${msg}`);
      });
    });

    this.network.sub(this, NetworkEvents.LOADING_DATA_SUCCESS, () => {
      this.forceUpdate();
    });

    PB.sub(this, 'relation', 'user.stickyNodes.get', () => {
      ViewManager.getCrossViewConfig(`$.stickyNode`).then(res => {
        // console.log(' user.stickyNodes.get ->  getCrossViewConfig ->  res:', res );
        let currentUserId = parseInt(localStorage.getItem('userId'));

        let getData = localStorage.getItem(`userStickyNodes-${currentUserId}`);
        if (res.data) {
          getData = res.data;
        } else if (!getData) {
          // 缓存无数据（null）时，初始化数据格式
          // 本地缓存被清理时，服务器有数据时，服务器数据为最新内容
          getData = {
            userId: currentUserId,
            name: '便签记事本',
            text: '',
            updateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            asyncTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            fontSize: 1,
          }
        } else if (getData && (getData[0] === '{' && getData[getData.length - 1] === '}')) {
          // 缓存有数据时，数据是json格式时
          getData = JSON.parse(getData);
        } else {
          // 缓存有数据时，数据是纯文本格式时（兼容第一版）
          getData = {
            userId: currentUserId,
            name: '便签记事本',
            text: `${getData}`,
            updateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            asyncTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            fontSize: 1,
          }
        }

        localStorage.setItem(`userStickyNodes-${currentUserId}`, JSON.stringify(getData));
      }).catch(({code, msg}) => {
        PB.emit('relation', 'user.stickyNodes.load_failed', {code, msg});
        console.log(`获取用户便签内容失败，code: ${code}, msg: ${msg}`);
      });
    });

    PB.emit('relation', 'user.stickyNodes.get');
  }

  changeVisionConfig = viewVisionConfig => {
    this.network.updateConfig('$.viewConfig.vision', viewVisionConfig).then(() => {
      this.setState({viewVisionConfig});
      message.success('操作成功');
    }).catch(({code, msg}) => {
      console.log(`修改视觉配置失败，code: ${code}, msg: ${msg}`);
    });
  };

  refreshPage = () => {
    window.location.reload();
  }

  render() {
    let me = this;
    const { changePwdStatus, userInfo, match } = me.props;

    // 判断频道域名, 生成 dashboard页面路径
    let defaultHomePath = undefined;
    const { hostname = undefined } = window.location;
    if (hostname === "edu.joinmap.ai") {
      defaultHomePath = "/channel/education";
    } else if (hostname === "hfyz.joinmap.ai") {
      defaultHomePath = "/channel/hfyz";
    } else if (hostname === "ustcsv.joinmap.ai") {
      defaultHomePath = "/channel/ustcsv";
    } else if (hostname === "localhost_x") {
      // 测试
      defaultHomePath = "/channel/hfyz";
    }
    // end 判断频道域名

    const currentUserId = parseInt(localStorage.getItem("userId"));
    // 关系图传参
    const relationProps = {
      userInfo,
      readonly: match.params.category !== "relation", // 只有relation才出现信息板，tip:可以放到route view 里面
      networkRef: this.network,
      visionConfig: this.state.viewVisionConfig,
    };
    const datasets = this.network.getData().data;

    let hideAiDialogBoxFlag = me.state.hideAiDialogBoxFlag===-1?Number(localStorage.getItem('hide_ai_dialog_box_flag')||0):me.state.hideAiDialogBoxFlag;
    let hideInputBoxFlag = me.state.hideInputBoxFlag===-1?Number(localStorage.getItem('hide_input_box_flag')||0):me.state.hideInputBoxFlag;
    let hideMicroservicesBoxFlag = me.state.hideMicroservicesBoxFlag===-1?Number(localStorage.getItem('hide_microservices_box_flag')||0):me.state.hideMicroservicesBoxFlag;
    let hideViewBoxFlag = me.state.hideViewBoxFlag===-1?Number(localStorage.getItem('hide_view_box_flag')||0):me.state.hideViewBoxFlag;

    return localStorage.getItem('token')?(
      <div className={style["container"]}>
        <Loading />
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            opacity: 0,
            width: "1rem",
            height: "1rem",
          }}
          ref={(ele) => {
            if (!ele || ele === me.calcElement) return;
            me.calcElement = ele;
            let calcBoundingClientRect = ele.getBoundingClientRect();
            me.remToPx = calcBoundingClientRect.height;
            me.fileListModalStyle = undefined;
            me.forceUpdate();
          }}
        >
          empty
        </div>
        <Content className={style["container-body"]}>
          <Switch>
            <Route
              exact
              path="/mainview/relation/:viewId?"
              render={({ match, history }) => {
                return (
                  <div
                    style={{ flex: 1, position: "relative", display: "flex" }}
                    onContextMenu={() => {
                      window.event.returnValue = false;
                      return false;
                    }}
                  >
                    <Relation
                      {...relationProps}
                      viewId={match.params.viewId ? match.params.viewId : null}
                      graphOnly={me.graphOnly}
                      reloadTeamworkOnly={me.reloadTeamworkOnly}
                      hideAiDialogBoxFlag={hideAiDialogBoxFlag}
                      hideInputBoxFlag={hideInputBoxFlag}
                      hideMicroservicesBoxFlag={hideMicroservicesBoxFlag}
                      hideViewBoxFlag={hideViewBoxFlag}
                    />
                    <NodeFilter
                      viewDataProvider={this.network}
                      viewId={match.params.viewId ? match.params.viewId : null}
                    />

                    <div
                      className={style["menu-bar"]}
                      style={{ visibility: SysUIConfig.MainMenu.visibility }}
                      onContextMenu={() => {
                        window.event.returnValue = false;
                        return false;
                      }}
                    >
                    {this.network.viewInfo && <BusinessTool
                        readonly={false} // {match.params.category !== 'relation'} // 只有relation才可编辑
                        viewStatus={match.params.category === "relation"} // 只有relation才出现分析菜单
                        className={style["bar"]}
                        networkRef={this.network}
                        userInfo={userInfo}
                        viewId={match.params.viewId}
                        viewInfo={this.network.viewInfo}
                        visionConfig={this.state.viewVisionConfig}
                        changeVisionConfig={this.changeVisionConfig}
                        isJoinmapUser={me.isJoinmapUser}
                        currentNodeId={this.state.currentNodeId}
                      /> }
                    {!this.network.viewInfo && 
                      <div className={style["bar"] + ' ' + toolbarStyle['frame'] + ' dark-theme'}>
                        <a className={toolbarStyle['frame-logo']} href='/'>
                            <img src='/assets/joinmap128.png' alt="logo" style={{marginTop: '2px'}}/>
                        </a>
                      </div> } 
                    </div>
                    {me.graphOnly ? null : (
                      <BadgeInfo
                        viewDataProvider={this.network}
                        currentUserInfo={userInfo}
                        doChangePwd={this.props.doChangePwd}
                        onLogout={this.props.doLogout}
                        history={history}
                        changeUserBasicInfoStatus={
                          this.props.changeUserBasicInfoStatus
                        }
                        onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
                        style={{ visibility: SysUIConfig.BadgeInfo.visibility }}
                      />
                    )}
                    {this.network.viewInfo ? (
                      <ShortcutMenu
                        history={history}
                        networkRef={this.network}
                        viewInfo={this.network.viewInfo}
                        currentNodeId={this.state.currentNodeId}
                      />
                    ) : null}

                    {this.network.viewInfo ? (
                      <StatisticsIndexPlugin
                        viewId={match.params.viewId}
                        readonly={
                          !this.network.viewInfo ||
                          currentUserId !==
                            parseInt(this.network.viewInfo.userId)
                        }
                        viewDataProvider={this.network}
                      />
                    ) : null}

                    {this.network.viewInfo && !hideViewBoxFlag ? (
                      <ChartsToolbarNew
                        networkRef={this.network}
                        nodes={this.network.getNode()}
                        viewInfo={this.network.viewInfo}
                        viewId={this.network.viewId}
                        className={style["chart-nav-bar"]}
                        readonly={
                          !this.network.viewInfo ||
                          currentUserId !==
                            parseInt(this.network.viewInfo.userId)
                        }
                      />
                    ) : null}

                    <RelationAIConsole currentUserInfo={userInfo} viewId={this.network.viewId} networkRef={this.network}
                      hideAiDialogBoxFlag={hideAiDialogBoxFlag}
                      dataLists={datasets}/>
                    {intl.get('locale')==='zh-cn' && 
                    <ExploreToolBar
                      userInfo={userInfo}
                      viewDataProvider={this.network}
                      projectId={this.projectId}
                      hideMicroservicesBoxFlag={hideMicroservicesBoxFlag}
                    />
                    }
                    <RelationSearch
                      viewId={match.params.viewId}
                      networkRef={this.network}
                      dataLists={datasets}
                      typeFieldName={TYPE_FIELD_NAME}
                      readonly={false}
                      areaTop={44}
                      dockAtTop={0 - 8 - (164 * 120) / 172} //@see @/style/components/main.toolbar.chart.nav.less
                      hideInputBoxFlag={hideInputBoxFlag}
                    />

                    {me.remToPx && me.network.viewInfo ? (
                      <Modal
                        visible={me.state.showFileList}
                        title={"图谱附件"}
                        onCancel={() => me.setState({ showFileList: false })}
                        footer={[
                          <Button
                            key={"close"}
                            onClick={() => me.setState({ showFileList: false })}
                          >
                            关闭
                          </Button>,
                        ]}
                        /*initialWidth={45 * me.remToPx}
                        initialHeight={200}*/
                        width={"70rem"}
                      >
                        <FileList
                          visible={me.state.showFileList}
                          viewId={me.network.viewInfo.viewId}
                          networkRef={me.network}
                          readonly={
                            currentUserId !==
                            parseInt(me.network.viewInfo.userId)
                          }
                          viewCreateUserId={parseInt(
                            me.network.viewInfo.userId
                          )}
                        />
                      </Modal>
                    ) : null}
                    {this.network.viewInfo ? (
                      <JoinTypeModal
                        visible={me.state.showJoinTypeModal}
                        viewId={match.params.viewId}
                        readonly={
                          !this.network.viewInfo ||
                          currentUserId !==
                            parseInt(this.network.viewInfo.userId)
                        }
                        viewInfo={this.network.viewInfo}
                        onClose={() =>
                          me.setState({ showJoinTypeModal: false })
                        }
                      />
                    ) : null}
                    {!me.graphOnly &&
                      me.state.userNodeAmountStatisticsUserInfo && (
                        <Modal
                          title={`全图节点统计【 用户：${me.state.userNodeAmountStatisticsUserInfo.nick} 】`}
                          onCancel={() =>
                            me.setState({
                              showUserNodeAmountStatisticsModal: false,
                              userNodeAmountStatisticsUserInfo: undefined,
                            })
                          }
                          visible={me.state.showUserNodeAmountStatisticsModal}
                          width={"55rem"}
                          footer={[
                            <Button
                              key={"close"}
                              onClick={() =>
                                me.setState({
                                  showUserNodeAmountStatisticsModal: false,
                                  userNodeAmountStatisticsUserInfo: undefined,
                                })
                              }
                            >
                              关闭
                            </Button>,
                          ]}
                          destroyOnClose={true}
                        >
                          <StatisticsUserNodePanel
                            userInfo={me.state.userNodeAmountStatisticsUserInfo}
                          />
                        </Modal>
                      )}
                  </div>
                );
              }}
            />
            <Route
              path="/mainview/dashboard"
              render={(p) => {
                if (defaultHomePath) {
                  return <Redirect to={defaultHomePath} />;
                }
                return (
                  <DashBoard
                    url={p.location.pathname} // 页面切换时触发render
                    userInfo={p.userInfo}
                    loginStatus={p.loginStatus}
                    history={p.history}
                    onLogout={this.props.doLogout}
                    doChangePwd={this.props.doChangePwd}
                    changeUserBasicInfoStatus={
                      this.props.changeUserBasicInfoStatus
                    }
                    onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
                    networkRef={this.network}
                  />
                );
              }}
            />
            <Route path="/mainview/account">
              <AccountCenter
                baseUri={"/mainview/account"}
                userInfo={this.props.userInfo}
                changeUserBasicInfoStatus={this.props.changeUserBasicInfoStatus}
                onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
                changePwdStatus={changePwdStatus}
                doChangePwd={this.props.doChangePwd}
                resetChangePwdStatus={this.props.resetChangePwdStatus}
              />
            </Route>
            <Route path={'/mainview/extended_functionality/:category?/:id?'}
              render={(p) => {
                return (
                  <ExtendedFunctionality
                    url={p.location.pathname} // 页面切换时触发render
                    userInfo={p.userInfo}
                    loginStatus={p.loginStatus}
                    history={p.history}
                    onLogout={this.props.doLogout}
                    doChangePwd={this.props.doChangePwd}
                    changeUserBasicInfoStatus={
                      this.props.changeUserBasicInfoStatus
                    }
                    onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
                    networkRef={this.network}
                    viewId={match.params.viewId ? match.params.viewId : null}
                  />
                );
              }}
            />
            <Route path={'/mainview/dataset/:category?/:id?'}
              render={(p) => {
                return (
                  <DataSet
                    url={p.location.pathname} // 页面切换时触发render
                    userInfo={p.userInfo}
                    loginStatus={p.loginStatus}
                    history={p.history}
                    onLogout={this.props.doLogout}
                  />
                );
              }}
            />
            <Route
              path="/mainview"
              render={() => <Redirect to="/mainview/dashboard" />}
            />
          </Switch>
          {/*<Copyright className={style["version"]}/>*/}
        </Content>
        <Feedback
          feedbackLoading={this.props.feedbackLoading}
          feedbackResult={this.props.feedbackResult}
          submitFeedbackAction={this.props.submitFeedbackAction}
        />
        <Guide />
        <Printing
          userInfo={this.props.userInfo}
          printingLoading={this.props.feedbackLoading}
          printingResult={this.props.printingResult}
          submitPrintingAction={this.props.submitPrintingAction}
        />
        <Guide />
        <StickyNodes />
        <NodeTodo currentUserId={currentUserId} />
        {/* 提示教程 */}
        <TutorialCourser history={this.props.history} userInfo={userInfo} />
      </div>
    ):(<></>);
  }
}

export default connect(
  state => ({
    loginStatus: state.account.loginStatus,
    userInfo: state.account.userInfo,
    changePwdStatus: state.account.changePwdStatus,
    feedbackLoading: state.system.feedbackLoading,
    feedbackResult: state.system.feedbackResult,
    printingLoading: state.system.printingLoading,
    printingResult: state.system.printingResult,
    changeUserBasicInfoStatus: state.account.changeUserBasicInfoStatus,
  }),
  dispatch => ({
    magicLogin: () => dispatch(doMagicLoginAction()),
    doLogout: () => dispatch(doLogoutAction()),
    submitFeedbackAction: (params) => dispatch(submitFeedbackAction(params)),
    submitPrintingAction: (params) => dispatch(submitPrintingAction(params)),
    doChangePwd: (oldPwd, newPwd, strength) => dispatch(doChangePwdAction(oldPwd, newPwd, strength)),
    resetChangePwdStatus: () => dispatch(changePwdInitAction()),
    onChangeUserBasicInfo: (userInfo) => dispatch(changeUserBasicInfoAction(userInfo)),
  })
)(MainView)
